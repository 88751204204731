import ReactGA from "react-ga4";
export const initGA = () => {       
    ReactGA.initialize(process.env.REACT_APP_GA_CONFIG);
} 

export const GApageView = (page) => {   
  ReactGA.send("pageview");   
}

export const GAmodalView = (modal) => {
  ReactGA.modalview(modal);
};

export const GAevent = (categoryName, eventName) => {
  ReactGA.event({       
      category: categoryName,  // Required
      action: eventName,       // Required
      label: 'labelName',       
      value: 10,       
      nonInteraction: false     
  });   
}

export const GAException = (error) => {
    ReactGA.exception({
    description: error,
    fatal: true
    });
}