export default {
  en: {
    autoPush: "Send push automatically",
    "error.config": "There was a configuration error",
    "error.required.authParams":
      'Missing parameters for the configured authentication scheme - "OAUTH2"',
    "error.required.baseUrl": '"baseUrl" is a required widget parameter',
    "error.required.success": "A success handler is required",
    "error.required.el": '"el" is a required widget parameter',
    "error.invalid.colors.brand":
      '"colors.brand" must be in six-digit hex format',
    "error.unsupported.browser": "Unsupported browser",
    "error.unsupported.cors": "Unsupported browser - missing CORS support",
    "error.unsupported.localStorage":
      "Unsupported browser - missing localStorage support",
    "error.enabled.cors":
      "There was an error sending the request - have you enabled CORS?",
    "error.expired.session":
      "Your session has expired. Please try to sign in again.",
    "error.mfa.only.expired.session": "Unable to authenticate at this time.",
    "error.auth.lockedOut":
      "Your account is locked. Please contact your administrator.",
    "error.oauth.idToken":
      "There was a problem generating the id_token for the user. Please try again.",
    "error.network.connection":
      "Unable to connect to the server. Please check your network connection.",
    "error.username.required": "Please enter a username",
    "error.password.required": "Please enter a password",
    "error.mfa.required":
      "The current sign on policy requires this client to redirect for re-authentication or MFA. Please contact your administrator.",
    "error.unsupported.response":
      "There was an unsupported response from server.",
    // ERROR MESSAGE OVERRIDES
    "errors.E0000004": "Unable to sign in",
    "errors.E0000069":
      "Your account is locked because of too many authentication attempts.",
    "errors.E0000047":
      "You exceeded the maximum number of requests. Try again in a while.",
    "errors.E0000079":
      "The operation is not allowed. Please refresh the page to proceed.",
    // Factors,
    "factor.totpSoft.oktaVerify": "Okta Verify",
    "factor.totpSoft.googleAuthenticator": "Google Authenticator",
    "factor.totpSoft.description": "Enter single-use code from the mobile app.",
    "factor.totpHard.description":
      "Enter a single-use code from a hardware token.",
    "factor.totpHard.yubikey": "YubiKey",
    "factor.totpHard.yubikey.description":
      "Insert your YubiKey and tap it to get a verification code.",
    "factor.totpHard.yubikey.placeholder": "Click here, then tap your YubiKey",
    "factor.oktaVerifyPush": "Use a push notification sent to the mobile app.",
    "factor.push.description":
      "Use a push notification sent to the mobile app.",
    "factor.duo.description":
      "Use Push Notification, SMS, or Voice call to authenticate.",
    "factor.call": "Voice Call Authentication",
    "factor.call.description":
      "Use a phone to authenticate by following voice instructions.",
    "factor.call.time.warning":
      "Haven't received a voice call? To try again, click <span style='font-weight:bold'>Redial</span>.",
    "factor.windowsHello": "Windows Hello",
    "factor.windowsHello.signin.description":
      "Sign in to Okta using Windows Hello.",
    "factor.windowsHello.signin.description.generic":
      "Sign in using Windows Hello.",
    "factor.windowsHello.signin.description.specific":
      "Sign in to {0} using Windows Hello.",
    "factor.u2f": "Security Key (U2F)",
    "factor.u2f.description":
      "Use a Universal 2nd Factor (U2F) security key to sign on to Okta.",
    "factor.u2f.description.generic":
      "Use a Universal 2nd Factor (U2F) security key to sign in.",
    "factor.u2f.description.specific":
      "Use a Universal 2nd Factor (U2F) security key to sign in to {0}.",
    "factor.email": "Email Authentication",
    "factor.email.description": "Enter a verification code sent to your email.",
    "factor.password": "Password",
    "factor.customFactor.description":
      "Redirect to a third party MFA provider to sign in to Okta.",
    "factor.customFactor.description.generic":
      "Redirect to a third party MFA provider to sign in.",
    "factor.customFactor.description.specific":
      "Redirect to a third party MFA provider to sign in to {0}.",
    "factor.webauthn.biometric": "Security Key or Biometric Authenticator",
    "factor.webauthn.biometric.description":
      "Use a security key (USB or bluetooth) or a biometric authenticator (Windows Hello, Touch ID, .)",
    "factor.hotp.description": "Enter a single-use code from an authenticator.",

    // Pages,
    //  Common properties
    "mfa.challenge.verify": "Verify",
    "mfa.challenge.answer.placeholder": "Answer",
    "mfa.challenge.answer.tooltip": "Answer",
    "mfa.challenge.answer.showAnswer": "Show",
    "mfa.challenge.answer.hideAnswer": "Hide",
    "mfa.challenge.enterCode.placeholder": "Enter Code",
    "mfa.challenge.enterCode.tooltip": "Enter Code",
    "mfa.challenge.password.placeholder": "Password",
    "mfa.backToFactors": "Back to factor list",
    "mfa.enroll": "Enroll",
    "mfa.phoneNumber.placeholder": "Phone number",
    "mfa.phoneNumber.ext.placeholder": "Extension",
    "mfa.sendCode": "Send code",
    "mfa.sent": "Sent",
    "mfa.resendCode": "Re-send code",
    "mfa.call": "Call",
    "mfa.calling": "Calling",
    "mfa.redial": "Redial",
    "mfa.sendEmail": "Send email",
    "mfa.resendEmail": "Re-send email",
    "mfa.scanBarcode": "Scan barcode",
    "mfa.altQrCode": "qr code",
    "mfa.noAccessToEmail": "Can't access email",
    "password.reset": "Reset Password",
    "password.oldPassword.placeholder": "Old password",
    "password.oldPassword.tooltip": "Old password",
    "password.newPassword.placeholder": "New password",
    "password.newPassword.tooltip": "New password",
    "password.confirmPassword.placeholder": "Repeat password",
    "password.confirmPassword.tooltip": "Repeat password",
    "password.error.match": "New passwords must match",
    "password.enroll.error.match": "Passwords must match",
    "recovery.sms.hint":
      "SMS can only be used if a mobile phone number has been configured.",
    "recovery.mobile.hint":
      "{0} can only be used if a mobile phone number has been configured.",
    "recovery.call": "Voice Call",
    "verify.choices.description": "Verify with one of the following factors.",
    // ## Email enrollment and verification

    "email.button.send": "Send me the code",
    "email.button.resend": "Send again",
    "email.code.label": "Verification code",
    "email.code.not.received": "Haven't received an email?",
    "email.enroll.title": "Set up Email Authentication",
    "email.enroll.description":
      "Send a verification code to your registered email.",
    "email.enroll.enterCode": "Enter code",
    "email.link.terminal.msg":
      "To finish signing in, return to the screen where you requested the email link.",
    "email.mfa.title": "Verify with Email Authentication",
    "email.mfa.description": "Send a verification code to {0}.",
    "email.mfa.email.sent.description":
      "A verification code was sent to {0}. Check your email and enter the code below.",
    "email.mfa.email.sent.description.sentText":
      "A verification code was sent to",
    "email.mfa.email.sent.description.emailCodeText":
      "Check your email and enter the code below.",
    // ## Enroll Choices
    "enroll.choices.title": "Set up multifactor authentication",
    "enroll.choices.description":
      "Your company requires multifactor authentication to add an additional layer of security when signing in to your Okta account",
    "enroll.choices.description.generic":
      "Your company requires multifactor authentication to add an additional layer of security when signing in to your account",
    "enroll.choices.description.specific":
      "Your company requires multifactor authentication to add an additional layer of security when signing in to your {0} account",
    "enroll.choices.description.gracePeriod.bold":
      "Your company recommends setting up additional factors for authentication. Set up will be required in: <b>{0} day(s)</b>.",
    "enroll.choices.description.gracePeriod.oneDay.bold":
      "Your company recommends setting up additional factors for authentication. Set up will be required in: <b>less than 1 day</b>.",
    "enroll.choices.optional":
      "You can configure any additional optional factor or click finish",
    "enroll.choices.list.setup": "Setup required",
    "enroll.choices.list.enrolled": "Enrolled factors",
    "enroll.choices.list.optional": "Additional optional factors",
    "enroll.choices.setup": "Setup",
    "enroll.choices.setup.another": "Set up another",
    "enroll.choices.submit.finish": "Finish",
    "enroll.choices.submit.configure": "Configure factor",
    "enroll.choices.submit.next": "Configure next factor",
    "enroll.choices.cardinality.setup": "({0} set up)",
    "enroll.choices.cardinality.setup.remaining": "({0} of {1} set up)",
    "enroll.choices.setup.skip": "Skip set up",
    "enroll.password.setup": "Select a password",
    "save.password": "Save password",
    "enroll.call.setup": "Follow phone call instructions to authenticate",
    "enroll.yubikey.title": "Setup YubiKey",
    "enroll.yubikey.subtitle":
      "Insert your YubiKey into a USB port and tap it to generate a verification code",
    "enroll.totp.title": "Setup {0}",
    "enroll.totp.selectDevice": "Select your device type",
    "enroll.totp.downloadApp":
      "Download <a href='{0}' class='inline-link'>{1} from the {2}</a> onto your mobile device.",
    // ## Enroll Windows Hello
    "enroll.windowsHello.title": "Enroll Windows Hello",
    "enroll.windowsHello.subtitle":
      "Click below to enroll Windows Hello as a second form of authentication",
    "enroll.windowsHello.subtitle.loading":
      "Please wait while Windows Hello is loading...",
    "enroll.windowsHello.save": "Enroll Windows Hello",
    "enroll.windowsHello.error.notWindows":
      "Windows Hello can only be used on Windows Edge with Windows 10. Contact your admin for assistance.",
    "enroll.windowsHello.error.notConfiguredHtml":
      "Windows Hello is not configured. Select the <b>Start</b> button, then select <b>Settings</b> - <b>Accounts</b> - <b>Sign-in</b> to configure Windows Hello.",
    // ## Verify Windows Hello
    "verify.windowsHello.subtitle": "Verify your identity with Windows Hello",
    "verify.windowsHello.subtitle.loading":
      "Please wait while Windows Hello is loading...",
    "verify.windowsHello.subtitle.signingIn": "Signing in to Okta...",
    "verify.windowsHello.subtitle.signingIn.generic": "Signing in...",
    "verify.windowsHello.subtitle.signingIn.specific": "Signing in to {0}...",
    "verify.windowsHello.save": "Verify with Windows Hello",
    "verify.windowsHello.error.notFound":
      "Your Windows Hello enrollment does not match our records. Contact your administrator for assistance.",
    "verify.windowsHello.error.notFound.selectAnother":
      "Your Windows Hello enrollment does not match our records. Select another factor or contact your administrator for assistance.",
    // ## Security Key (U2F)
    "enroll.u2f.title": "Setup Security Key (U2F)",
    "enroll.u2f.save": "Register Security Key",
    "enroll.u2f.general2":
      "Make sure you have a Security Key. If already inserted, remove it now.<br>If you have a Bluetooth Security Key, turn on your computer's Bluetooth.",
    "enroll.u2f.general3": "Click the button below to register",
    "enroll.u2f.instructions":
      "Insert your Security Key into a USB port on this computer. If it has a button or gold disk, tap it.",
    "enroll.u2f.instructionsBluetooth":
      "If you are using a Bluetooth Security Key, press the button.",
    "u2f.error.factorNotSupported":
      "Security Key (U2F) is not supported on this browser. Select another factor or contact your admin for assistance.",
    "u2f.error.factorNotSupported.oneFactor":
      "Security Key (U2F) is not supported on this browser. Contact your admin for assistance.",
    "u2f.error.other":
      "An unknown error has occured. Try again or select another factor.",
    "u2f.error.other.oneFactor":
      "An unknown error has occured. Try again or contact your admin for assistance.",
    "u2f.error.badRequest":
      "There was an error with the U2F request. Try again or select another factor.",
    "u2f.error.badRequest.oneFactor":
      "There was an error with the U2F request. Try again or contact your admin for assistance.",
    "u2f.error.unsupported":
      "The security key is unsupported. Select another factor.",
    "u2f.error.unsupported.oneFactor":
      "The security key is unsupported. Contact your admin for assistance.",
    "u2f.error.timeout":
      "You have timed out of the authentication period. Please try again.",
    "verify.u2f.instructions":
      "Insert your Security Key. If it has a button or gold disk, tap it.",
    "verify.u2f.instructionsBluetooth":
      "If you are using a Bluetooth Security Key, turn on your computer's Bluetooth and press the button.",
    "verify.u2f.retry": "Retry",
    // ## WebAuthn
    "enroll.webauthn.biometric.title":
      "Set up security key or biometric authenticator",
    "enroll.webauthn.save": "Enroll",
    "enroll.webauthn.biometric.instructions":
      "Your browser will prompt to register a security key or biometric authenticator (Windows Hello, Touch ID, .). Follow the instructions to complete enrollment.",
    "enroll.webauthn.biometric.instructions.edge":
      "Note: If you are enrolling a security key and Windows Hello or PIN is enabled, you will need to select '<b>Cancel</b>' in the prompt before continuing.",
    "verify.webauthn.biometric.instructions":
      "Your browser or device will prompt you to verify with a security key or biometric authenticator. Follow the instructions to complete authentication.",
    "webauthn.biometric.error.factorNotSupported":
      "Security key or biometric authenticator is not supported on this browser. Select another factor or contact your admin for assistance.",
    "webauthn.biometric.error.factorNotSupported.oneFactor":
      "Security key or biometric authenticator is not supported on this browser. Contact your admin for assistance.",
    "enroll.webauthn.instructions.noSupportForBiometric":
      "Note: Some browsers may not support biometric authenticators.",
    "authfactor.webauthn.error":
      "You are currently unable to use a Security key or biometric authenticator. Try again.",
    // ## Enter Passcode Form
    "enroll.totp.enterCode": "Enter code displayed from the application",
    "enroll.totp.setupApp":
      "Launch {0} application on your mobile device and select Add an account.",
    "enroll.totp.setupGoogleAuthApp":
      'Launch {0}, tap the "+" icon, then select "Scan barcode".',
    "enroll.totp.cannotScan": "Can't scan?",
    "enroll.totp.refreshBarcode": "Refresh code",
    // ## Manually Setup Push
    "enroll.totp.cannotScanBarcode": "Can't scan barcode?",
    "enroll.totp.manualSetupInstructions":
      "To set up manually enter your Okta Account username and then input the following in the Secret Key Field",
    "enroll.totp.manualSetupInstructions.generic":
      "To set up manually enter your Account username and then input the following in the Secret Key Field",
    "enroll.totp.manualSetupInstructions.specific":
      "To set up manually enter your {0} Account username and then input the following in the Secret Key Field",
    "enroll.totp.sharedSecretInstructions":
      "Enter your Okta Account username and enter the following in the Secret Key Field",
    "enroll.totp.sharedSecretInstructions.generic":
      "Enter your Account username and enter the following in the Secret Key Field",
    "enroll.totp.sharedSecretInstructions.specific":
      "Enter your {0} Account username and enter the following in the Secret Key Field",
    "enroll.totp.sendSms": "Send activation link via SMS",
    "enroll.totp.sendEmail": "Send activation link via email",
    "enroll.totp.setupManually": "Setup manually without push notification",
    // ## Enrollment Link Sent
    "enroll.totp.enrollViaEmail.title": "Activation email sent!",
    "enroll.totp.enrollViaEmail.msg": "Open the email from your mobile device.",
    "enroll.totp.enrollViaSms.title": "SMS sent!",
    "enroll.totp.enrollViaSms.msg": "View the SMS on your mobile device.",
    "recoveryChallenge.call.title":
      "Enter verification code received via Voice Call",

    "mfa.factors.dropdown.title": "Select an authentication factor",
    "mfa.factors.dropdown.sr.text":
      "Select Authentication Factor - {0} Factor Selected",
    "mfa.duoSecurity.push": "Push — {0}",
    "mfa.duoSecurity.sms": "SMS — {0}",
    "mfa.duoSecurity.call": "Call — {0}",
    "mfa.switch": "Switch Factor",
    "mfa.challenge.title": "Enter your {0} passcode",
    "mfa.challenge.orEnterCode": "Or enter code",
    "mfa.challenge.totp.subtitle.multiple":
      "Enter code from any registered {0} device.",
    "mfa.emailVerification.checkEmail":
      "To finish signing in, click the link in your email.",
    "mfa.emailVerification.title": "Sign in using a link sent to your email.",
    "mfa.emailVerification.subtitle": "Emails will be sent to {0}",
    "mfa.emailVerification.otc.finish":
      "To finish signing in, enter the code which was emailed to you.",
    "oktaverify.send": "Send Push",
    "oktaverify.resend": "Re-send Push",
    "oktaverify.sent": "Push sent!",
    "oktaverify.rejected": "You have chosen to reject this login.",
    "oktaverify.rejected.upgradeRequired.ios":
      "Verification failed because your Okta Verify version is no longer supported. To sign in, please update Okta Verify on the App Store, then try again.",
    "oktaverify.rejected.upgradeRequired.android":
      "Verification failed because your Okta Verify version is no longer supported. To sign in, please update Okta Verify on Google Play, then try again.",
    "oktaverify.timeout": "Your push notification has expired.",
    "oktaverify.warning":
      "Haven't received a push notification yet? Try opening the Okta Verify App on your phone.",
    "oktaverify.numberchallenge.instruction":
      "On your phone, tap <span class='challenge-number'>{0}</span> on Okta Verify prompt to continue.",
    "oktaverify.numberchallenge.explain":
      "This extra step helps us make sure it's really you signing in.",
    "primaryauth.title": " ",
    "primaryauth.username.placeholder": "Email address",
    "primaryauth.username.tooltip": "Username",
    "primaryauth.password.placeholder": "Password",
    "primaryauth.password.tooltip": "Password",
    "primaryauth.submit": "Log in",
    "primaryauth.newUser.tooltip":
      "This is the first time you are connecting to {0} from this browser",
    "primaryauth.newUser.tooltip.close": "Close",
    "oktaVerify.description":
      "To access this resource, your organization requires you to sign in using your device.",
    "oktaVerify.button": "Sign in with Okta FastPass",
    "idpDiscovery.email.placeholder": "Email",
    "password.forgot.email.or.username.placeholder": "Email or Username",
    "password.forgot.email.or.username.tooltip": "Email or Username",
    "password.forgot.sendText": "Reset via SMS",
    "password.forgot.sendEmail": "Reset via Email",
    "password.forgot.call": "Reset via Voice Call",
    "password.forgot.emailSent.title": "Email sent!",
    "password.forgot.emailSent.desc":
      "Email has been sent to {0} with instructions on resetting your password.",
    "password.forgot.question.title": "Answer Forgotten Password Challenge",
    "password.forgot.question.submit": "Reset Password",
    "password.forgot.code.notReceived":
      "Didn't receive a code? Reset via email",
    "password.forgot.noFactorsEnabled":
      "No password reset options available. Please contact your administrator.",
    "password.reset.title": "Reset your Okta password",
    "password.reset.title.generic": "Reset your password",
    "password.reset.title.specific": "Reset your {0} password",
    "password.complexity.requirements": "Password requirements: {0}.",
    "password.complexity.history":
      "Your password cannot be any of your last {0} passwords.",
    "password.complexity.minAgeMinutes":
      "At least {0} minute(s) must have elapsed since you last changed your password.",
    "password.complexity.minAgeHours":
      "At least {0} hour(s) must have elapsed since you last changed your password.",
    "password.complexity.minAgeDays":
      "At least {0} day(s) must have elapsed since you last changed your password.",
    "password.reset.verification":
      "Verify with one of the following factors to reset your password.",
    "password.complexity.length": "at least {0} characters",
    "password.complexity.list.element": ", {0}",
    "password.complexity.lowercase": "a lowercase letter",
    "password.complexity.uppercase": "an uppercase letter",
    "password.complexity.number": "a number",
    "password.complexity.symbol": "a symbol",
    "password.complexity.no_username": "no parts of your username",
    "password.complexity.no_first_name": "does not include your first name",
    "password.complexity.no_last_name": "does not include your last name",
    "password.complexity.requirements.header": "Password requirements:",
    "password.complexity.length.description": "At least {0} characters",
    "password.complexity.lowercase.description": "A lowercase letter",
    "password.complexity.uppercase.description": "An uppercase letter",
    "password.complexity.number.description": "A number",
    "password.complexity.symbol.description": "A symbol",
    "password.complexity.no_username.description": "No parts of your username",
    "password.complexity.no_first_name.description":
      "Does not include your first name",
    "password.complexity.no_last_name.description":
      "Does not include your last name",
    "password.complexity.history.description":
      "Your password cannot be any of your last {0} passwords",
    "password.complexity.minAgeMinutes.description":
      "At least {0} minute(s) must have elapsed since you last changed your password",
    "password.complexity.minAgeHours.description":
      "At least {0} hour(s) must have elapsed since you last changed your password",
    "password.complexity.minAgeDays.description":
      "At least {0} day(s) must have elapsed since you last changed your password",
    "password.expired.submit": "Change Password",
    "password.expired.title": "Your Okta password has expired",
    "password.expired.title.generic": "Your password has expired",
    "password.expired.title.specific": "Your {0} password has expired",
    "password.expiring.later": "Remind me later",
    "password.expiring.title": "Your password will expire in {0} days",
    "password.expiring.today": "Your password will expire later today",
    "password.expiring.soon": "Your password is expiring soon",
    "password.expiring.subtitle":
      "When password expires you may be locked out of Okta Mobile, mobile email, and other services.",
    "password.expiring.subtitle.generic":
      "When password expires you will be locked out of your account.",
    "password.expiring.subtitle.specific":
      "When password expires you will be locked out of your {0} account.",
    "password.expired.custom.submit": "Go to {0}",
    "password.expired.custom.subtitle":
      "This password is set on another website. Click the button below to go there and set a new password.",
    "customauth.sign.in.with.label": "Sign in with {0}",
    "authbutton.divider.text": "or",
    "piv.card": "PIV Card",
    "piv.card.insert":
      "Please insert your PIV card and select the user certificate.",
    "piv.card.error": "Certificate authentication failed. Contact your admin.",
    "piv.card.error.empty":
      "No certificate selected. Choose a certificate and try again.",
    "piv.card.error.invalid":
      "Certificate validation failed. Choose another certificate and try again.",
    "piv.cac.card": "Sign in with PIV / CAC card",
    "piv.cac.title": "PIV / CAC card",
    "piv.cac.card.insert":
      "Please insert your PIV / CAC card and select the user certificate.",
    "piv.cac.error":
      "There was an error signing in. Click the button below to try again.",
    "unsupported.oneDrive.title": "Your OneDrive version is not supported",
    "unsupported.oneDrive.desc":
      "Upgrade now by installing the OneDrive for Business Next Generation Sync Client to login to Okta",
    "unsupported.oneDrive.action": "Learn how to upgrade",
    "unsupported.cookies.title": "Cookies are required",
    "unsupported.cookies.desc":
      "Cookies are disabled on your browser. Please enable Cookies and refresh this page.",
    "unsupported.cookies.action": "Refresh",
    "deviceTrust.sso.text": "Sign in to access company resources",
    "deviceTrust.sso.subtitle.2":
      "Your company uses Okta Mobile to get you approved to access this app.",
    "deviceTrust.sso.button": "Sign in with Okta Mobile",
    "deviceTrust.sso.expire.title": "Oops let's get you back on track",
    "deviceTrust.sso.redirectText": "Verifying your identity",
    "deviceTrust.sso.expire.subtitle":
      "Unfortunately the steps required to sign in took longer than expected and your attempt timed out.",
    "deviceTrust.sso.expire.button": "Sign in using Okta Mobile",
    "deviceTrust.universalLink.fallback.getOktaMobile.title": "Get Okta Mobile",
    "deviceTrust.universalLink.fallback.getOktaMobile.subtitle":
      "Go to the {0}AppStore{1}, {0}search{1} for {0}Okta Mobile{1} and tap on {0}GET{1} Okta Mobile. Once installed, sign in to Okta Mobile and follow the instructions to secure your device.",
    "loopback.polling.cancel.link": "Cancel and take me to sign in",
    "loopback.polling.cancel.link.with.form.error": "Take me to sign in",
    "customUri.subtitle": "Launching Okta Verify...",
    "customUri.content":
      "<div class='skinny-content'> If nothing prompts from the browser, <a href='#' id='launch-ov' class='link'>click here</a> to launch Okta Verify, or make sure Okta Verify is installed.</div>",
    "customUri.title": 'Click "Open Okta Verify" on the browser prompt',
    "customUri.required.content.prompt": "Didn’t get a prompt?",
    "customUri.required.content.download.title": "Don’t have Okta Verify?",
    "customUri.required.content.download.linkText": "Download here",
    "customUri.required.content.button": "Launch Okta Verify",
    "universalLink.title": "Sign in with Okta FastPass",
    "universalLink.content":
      "Open Okta Verify to continue signing in. If Okta Verify did not open automatically, tap the button below to reopen Okta Verify.",
    "appLink.title": "Sign in with Okta FastPass",
    "appLink.content":
      "If Okta Verify did not open automatically, tap the button below to reopen Okta Verify.",
    "oktaVerify.open.button": "Open Okta Verify",
    "oktaVerify.reopen.button": "Reopen Okta Verify",
    "polling.title":
      "There are too many users trying to sign in right now. We will automatically retry in {0} seconds.",
    "poll.form.title": "Unable to complete your request",
    "poll.form.message": "We will automatically retry in <$1>{0}</$1> seconds.",
    "cert.authentication.title": "Certificate authentication",
    "common.logo.alt": "aria logo",
    "oie.authenticator.enroll.error.fail":
      "Unable to enroll authenticator. Try again.",
    "oie.authenticator.verify.error.fail":
      "Unable to verify authenticator. Try again.",
    "oie.form.field.optional": "Optional",
    "oie.password.label": "Password",
    "oie.password.newPasswordLabel": "New password",
    "oie.password.authenticator.description":
      "Choose a password for your account",
    "oie.password.challenge.title": "Verify with your password",
    "oie.password.enroll.title": "Set up password",
    "oie.password.passwordLabel": "Enter password",
    "oie.password.confirmPasswordLabel": "Re-enter password",
    "oie.password.reset.verification":
      "Verify with one of the following security methods to reset your password.",
    "idx.password.expiring.message":
      "When your password expires you will be locked out of your Okta account.",
    "oie.password.incorrect.message": "Password is incorrect",
    "oie.selfservice.reset.password.not.allowed":
      "Reset password is not allowed at this time. Please contact support for assistance.",
    "password.common":
      "This password was found in a list of commonly used passwords. Please try another password.",
    "idx.recovery.completed":
      "You can now sign in with your existing username and new password.",
    "oie.phone.label": "Phone",
    "oie.phone.authenticator.description":
      "Verify with a code sent to your phone",
    "oie.phone.enroll.sms.label": "SMS",
    "oie.phone.enroll.voice.label": "Voice call",
    "oie.phone.enroll.title": "Set up phone authentication",
    "oie.phone.enroll.call.subtitle":
      "Enter your phone number to receive a verification code via voice call.",
    "oie.phone.sms.primaryButton": "Receive a code via SMS",
    "oie.phone.sms.secondaryButton": "Receive an SMS instead",
    "oie.phone.call.primaryButton": "Receive a code via voice call",
    "oie.phone.call.secondaryButton": "Receive a voice call instead",
    "oie.phone.verify.call.sendText": "Send a code via voice call to",
    "oie.phone.verify.call.resendText": "Haven't received a call?",
    "oie.phone.verify.call.resendLinkText": "Call again",
    "oie.phone.verify.enterCodeText": "Enter the code below to verify.",
    "oie.phone.verify.title": "Verify with your phone",
    "oie.phone.alternate.title": "your phone",
    "oie.phone.carrier.charges": "Carrier messaging charges may apply",
    "oie.phone.invalid": "Invalid Phone Number.",
    "oie.email.label": "Email",
    "oie.email.authenticator.description":
      "Verify with a link or code sent to your email",
    "oie.email.mfa.title": "Verify with your email",
    "oie.email.verify.alternate.magicLinkToEmailAddress":
      "An email magic link was sent to <$1>{0}</$1>.",
    "oie.email.verify.alternate.magicLinkToYourEmail":
      "An email magic link was sent to <$1>your email</$1>.",
    "oie.email.verify.alternate.instructions":
      "Click the link in the email or enter the code below to continue.",
    "oie.email.verify.alternate.showCodeTextField":
      "Enter a code from the email instead",
    "oie.email.enroll.subtitle":
      "Please check your email and enter the code below.",
    "oie.email.return.link.expired.title": "Verify with your email",
    "oie.profile.additional.title": "Additional Profile information",
    "oie.profile.additional.secondemail.subtitle":
      "Use a second email to <$1> recover your account </$1> in case you become locked out. This email must be verified after setup.",
    "oie.webauthn.label": "Security Key or Biometric Authenticator",
    "oie.webauthn.description":
      "Use a security key or a biometric authenticator to sign in",
    "oie.enroll.webauthn.title":
      "Set up security key or biometric authenticator",
    "oie.enroll.webauthn.instructions":
      "You will be prompted to register a security key or biometric authenticator (Windows Hello, Touch ID, Face ID, .). Follow the instructions to complete set up.",
    "oie.enroll.webauthn.uv.required.instructions":
      "Biometric verification or a PIN is required to setup this authenticator.",
    "oie.enroll.webauthn.instructions.edge":
      "Note: If you are enrolling a security key and Windows Hello or PIN is enabled, you will need to select 'Cancel' in the prompt before continuing.",
    "oie.enroll.webauthn.save": "Set up",
    "oie.webauthn.error.not.supported":
      "Security key or biometric authenticator is not supported on this browser. Contact your admin for assistance.",
    "oie.verify.webauth.title":
      "Verify with Security Key or Biometric Authenticator",
    "oie.verify.webauthn.instructions":
      "You will be prompted to use a security key or biometric verification (Windows Hello, Touch ID, .). Follow the instructions to complete verification.",
    "oie.verify.webauthn.uv.required.instructions":
      "Biometric verification or a PIN is required to sign in with this authenticator.",
    "oie.verify.webauthn.cant.verify": "Can't verify?",
    "oie.verify.webauthn.cant.verify.biometric.authenticator.title":
      "Are you trying to use a biometric authenticator?",
    "oie.verify.webauthn.cant.verify.biometric.authenticator.description1":
      "Biometric authenticators (fingerprint, face recognition, PIN) will only work on the same device on which they were set up.",
    "oie.verify.webauthn.cant.verify.biometric.authenticator.description2":
      "If available, set up another security method on the device you used to set up your biometric authenticator.",
    "oie.verify.webauthn.cant.verify.security.key.title":
      "Are you trying to use a security key?",
    "oie.verify.webauthn.cant.verify.security.key.description":
      "If you have set up a security key, insert it in a USB port when prompted by the browser and tap on the button or gold disk. Security keys can work on multiple devices.",

    "oie.verify.webauthn.cant.verify.enrollment.step1":
      "Open your Okta Dashboard (e.g. yourcompany.okta.com) on the device you used to setup your security key or biometric authenticator",
    "oie.verify.webauthn.cant.verify.enrollment.step2":
      "Go to Settings > Security Methods",
    "oie.verify.webauthn.cant.verify.enrollment.step3":
      'On Okta Verify, click "Set up"',
    "oi.verify.webauthn.cant.verify.enrollment.step4":
      "Scan the QR code using Okta Verify and follow instructions to finish enrolling your account",
    "oie.security.question.label": "Security Question",
    "oie.security.question.enroll.title": "Set up security question",
    "oie.security.question.challenge.title":
      "Verify with your Security Question",
    "oie.security.question.questionKey.label": "Choose a security question",
    "oie.security.question.createQuestion.label":
      "Create my own security question",
    "oie.security.question.authenticator.description":
      "Choose a security question and answer that will be used for signing in",
    "securityQuestion.answer.tooShort.arg":
      "The security question answer must be at least {0} characters in length",
    "oie.okta_verify.label": "Okta Verify",
    "oie.okta_verify.authenticator.description":
      "Okta Verify is an authenticator app, installed on your phone, used to prove your identity",
    "oie.enroll.okta_verify.setup.title": "Set up Okta Verify",
    "oie.enroll.okta_verify.setup.email.title": "Check your email",
    "oie.enroll.okta_verify.setup.sms.title": "Check your text messages",
    "oie.enroll.okta_verify.select.channel.description":
      "Which option do you want to try?",
    "oie.enroll.okta_verify.select.channel.qrcode.label": "Scan a QR code",
    "oie.enroll.okta_verify.select.channel.email.label":
      "Email me a setup link",
    "oie.enroll.okta_verify.select.channel.sms.label": "Text me a setup link",
    "oie.enroll.okta_verify.select.channel.title": "More options",
    "oie.enroll.okta_verify.channel.email.label": "Email",
    "oie.enroll.okta_verify.enroll.channel.email.title":
      "Set up Okta Verify via email link",
    "oie.enroll.okta_verify.enroll.channel.sms.title":
      "Set up Okta Verify via SMS",
    "oie.enroll.okta_verify.channel.email.description":
      "Make sure you can access the email on your mobile device.",
    "oie.enroll.okta_verify.channel.sms.description":
      "Make sure you can access the text on your mobile device.",
    "oie.enroll.okta_verify.qrcode.step1":
      "On your mobile device, download the Okta Verify app from the App Store (iPhone and iPad) or Google Play (Android devices).",
    "oie.enroll.okta_verify.qrcode.step2":
      "Open the app and follow the instructions to add your account",
    "oie.enroll.okta_verify.qrcode.step3":
      "When prompted, tap Scan a QR code, then scan the QR code below:",
    "oie.enroll.okta_verify.qrcode.cannotScan": "Can't scan?",
    "oie.enroll.okta_verify.switch.channel.link.text":
      "Or <a href='#' class='switch-channel-link'>try a different way</a> to set up Okta Verify.",
    "oie.enroll.okta_verify.email.info":
      "We sent an email to <span class='strong'>{0}</span> with an Okta Verify setup link. To continue, open the link on your mobile device.",
    "oie.enroll.okta_verify.email.notReceived":
      "Haven’t received an email? Check your spam folder or <a href='# class='resend-link'>send again</a>",
    "oie.enroll.okta_verify.sms.info":
      "We sent an SMS to <span class='strong'>{0}</span> with an Okta Verify setup link. To continue, open the link on your mobile device.",
    "oie.enroll.okta_verify.sms.notReceived":
      "Haven’t received an SMS? <a href='#' class='resend-link'>Send again</a>",
    "oie.enroll.okta_verify.setupLink": "Send me the setup link",
    "oie.okta_verify.totp.title": "Enter a code",
    "oie.okta_verify.totp.enterCodeText": "Enter code from Okta Verify app",
    "oie.okta_verify.push.title": "Get a push notification",
    "oie.okta_verify.push.sent": "Push notification sent",
    "oie.okta_verify.push.resend": "Resend push notification",
    "oie.okta_verify.signed_nonce.label": "Use Okta FastPass",
    "oie.okta_verify.enroll.force.upgrade.title": "Update Okta Verify",
    "oie.authenticator.app.non_fips_compliant_enrollment_device_incompatible":
      "The device used to set up Okta Verify does not meet your organization’s security requirements because it is not FIPS compliant. Contact your administrator for help.",
    "oie.authenticator.app.non_fips_compliant_enrollment_app_update_required":
      "The Okta Verify version on the device used does not meet your organization’s security requirements. To add your account, update Okta Verify to the latest version, then try again.",
    "oie.authenticator.app.method.push.verify.enable.biometrics":
      "Your response was received, but your organization requires biometrics. Make sure your device supports biometrics, Okta Verify is up-to-date and biometrics are enabled for your account in Okta Verify, then try again.",
    "oie.authenticator.app.method.push.verify.enable.biometrics.title":
      "Enable biometrics in Okta Verify",
    "oie.authenticator.app.method.push.verify.enable.biometrics.description":
      "Your response was received, but your organization requires biometrics. Make sure you meet the following requirements, then try again:",
    "oie.authenticator.app.method.push.verify.enable.biometrics.point1":
      "Your device supports biometrics",
    "oie.authenticator.app.method.push.verify.enable.biometrics.point2":
      "Okta Verify is up-to-date",
    "oie.authenticator.app.method.push.verify.enable.biometrics.point3":
      "In Okta Verify, biometrics are enabled for your account",
    "oie.authenticator.app.method.push.enroll.enable.biometrics":
      "Your organization requires biometrics. To proceed, ensure your device supports biometrics, then add your account and enable biometrics when prompted.",
    "oie.authenticator.app.method.push.enroll.enable.biometrics.title":
      "Enable biometrics to add an account in Okta Verify",
    "oie.authenticator.oktaverify.method.totp.verify.enable.biometrics":
      "Your response was received, but your organization requires biometrics. Make sure your device supports biometrics, Okta Verify is up-to-date and biometrics are enabled for your account in Okta Verify, then try again.",
    "oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.title":
      "Enable biometrics in Okta Verify",
    "oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.description":
      "Your response was received, but your organization requires biometrics. Make sure you meet the following requirements, then try again:",
    "oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.point1":
      "Your device supports biometrics",
    "oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.point2":
      "Okta Verify is up-to-date",
    "oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.point3":
      "In Okta Verify, biometrics are enabled for your account",
    "oie.google_authenticator.label": "FreeOTP",
    "oie.google_authenticator.authenticator.description":
      "Enter a temporary code generated from the Google Authenticator app.",
    "oie.enroll.google_authenticator.setup.title": "Set up FreeOTP",
    "oie.enroll.google_authenticator.scanBarcode.title": "Scan barcode",
    "oie.enroll.google_authenticator.scanBarcode.description":
      'Launch Google Authenticator, tap the "+" icon, then select "Scan barcode".',
    "oie.enroll.google_authenticator.scanBarcode.cannotScan": "Can't scan?",
    "oie.enroll.google_authenticator.cannotScanBarcode.title":
      "Can't scan barcode?",
    "oie.enroll.google_authenticator.manualSetupInstructions":
      "To set up manually enter your Okta Account username and then input the following in the Secret Key Field",
    "oie.enroll.google_authenticator.enterCode.title":
      "Enter code displayed from application",
    "oie.verify.google_authenticator.otp.title": "Verify with FreeOTP",
    "oie.verify.google_authenticator.otp.description":
      "Enter the temporary code generated in your FreeOTP app",
    "oie.google_authenticator.otp.enterCodeText": "Enter code",
    "oie.verify.custom_app.title": "Verify with {0}",
    "oie.custom_app.push.sent": "Push notification sent",
    "oie.custom_app.push.resend": "Resend push notification",
    "oie.custom_app.push.warning":
      "Haven't received a push notification yet? Try opening {0} on your phone.",
    "oie.numberchallenge.warning":
      "Haven't received a push notification yet? Try opening the Okta Verify app on your device, or <$1>resend the push notification</$1>.",
    "oie.numberchallenge.instruction":
      "On your mobile device, open the Okta Verify prompt, then tap <$1>{0}</$1> in Okta Verify to continue.",
    "oie.numberchallenge.force.upgrade.title": "Update Okta Verify",
    "oie.authenticator.app.method.push.force.upgrade.number_challenge":
      "Your response was received, but your Okta Verify version is no longer supported by your organization. To verify your identity with push notifications, update Okta Verify to the latest version, then try again.",
    "oie.rsa.authenticator.description":
      "Verify by entering a code generated by RSA SecurID",
    "oie.idp.authenticator.description": "Redirect to verify with {0}.",
    "oie.idp.enroll.title": "Set up {0}",
    "oie.idp.enroll.description":
      "Clicking below will redirect to enrollment in {0}",
    "oie.idp.challenge.title": "Verify with {0}",
    "oie.idp.challenge.description":
      "You will be redirected to verify with {0}",
    "idx.identity.provider.auth.error":
      "There was a problem signing you into your identity provider. Please contact your administrator for help.",
    "oie.yubikey.label": "YubiKey Authenticator",
    "oie.yubikey.authenticator.description":
      "Verify your identity using YubiKey",
    "oie.yubikey.enroll.title": "Set up YubiKey",
    "oie.yubikey.challenge.title": "Verify with YubiKey",
    "oie.yubikey.description":
      "Insert the YubiKey into a USB port and tap it to generate a verification code.",
    "oie.yubikey.passcode.label": "Insert then tap your YubiKey",
    "oie.select.authenticators.enroll.title": "Set up security methods",
    "oie.select.authenticators.enroll.subtitle":
      "Security methods help protect your account by ensuring only you have access.",
    "oie.select.authenticators.enroll.subtitle.custom":
      "Security methods help protect your {0} account by ensuring only you have access.",
    "oie.setup.required": "Set up required",
    "oie.setup.optional": "Set up optional",
    "oie.optional.authenticator.button.title": "Finish",
    "oie.verify.authenticator.button.text": "Select",
    "oie.enroll.authenticator.button.text": "Set up",
    "oie.select.authenticators.verify.title":
      "Verify it's you with a security method",
    "oie.select.authenticators.verify.subtitle":
      "Select from the following options",
    "oie.success.text.signingIn": "Signing in",
    "oie.success.text.signingIn.with.ellipsis": "Signing in...",
    "oie.success.text.signingIn.with.appName": "Signing in to {0}",
    "oie.success.text.signingIn.with.appName.and.identifier":
      "Signing in to {0} as {1}",
    "oie.go.back": "Go back",
    "oie.enroll.switch.authenticator": "Return to authenticator list",
    "oie.enroll.skip.setup": "Skip set up",
    "oie.enroll.skip.profile": "Skip Profile",
    "oie.verification.switch.authenticator": "Verify with something else",
    "oie.remember": "Keep me signed in",
    "oie.try.again": "Try again",
    "enroll.title.oda": "Download Okta Verify",
    "enroll.title.oda.with.account":
      "Additional setup required to use Okta FastPass",
    "enroll.title.oda.without.account": "Set up an Okta Verify account",
    "enroll.title.mdm": "Additional setup required",
    "enroll.subtitle.fastpass":
      "On this device, do you already have an Okta Verify account for {0}?",
    "enroll.option.noaccount.fastpass": "No, I don’t have an account",
    "enroll.option.account.fastpass": "Yes, I already have an account",
    "enroll.explanation.p1":
      "To sign in using Okta Verify, you will need to set up Okta Verify on this device.",
    "enroll.explanation.p2":
      "In the app, follow the instructions to add an organizational account. When prompted, choose <span class='semi-strong'>Sign In</span>, then enter the <span class='semi-strong'>sign-in URL</span>:",
    "enroll.oda.android.step1": "On Google Play, download the Okta Verify app.",
    "enroll.oda.step1":
      "Open Okta Verify and follow the steps to add your account.",
    "enroll.oda.step2":
      "When prompted, choose Sign In, then enter the sign-in URL:",
    "enroll.oda.step3": "Download the Okta Verify app.",
    "enroll.oda.step6":
      "Finish setting up your account in Okta Verify, then try accessing this app again.",
    "enroll.oda.with.account.explanation":
      "Okta FastPass is a security method that can sign you in without needing your username.",
    "enroll.oda.with.account.subtitile1":
      "Already have Okta FastPass enabled for your account?",
    "enroll.oda.with.account.subtitile2":
      "Need to set up Okta FastPass for your account?",
    "enroll.oda.with.account.step1":
      "Make sure your Okta Verify is up-to-date, then try signing in again.",
    "enroll.oda.with.account.step2":
      "If your device has a work profile, make sure you are signing in from the same profile that Okta Verify is installed in.",
    "enroll.oda.with.account.step3":
      "On certain browsers and applications, Okta FastPass is not supported.",
    "enroll.oda.with.account.step4":
      "On this device, open the Okta Verify app.",
    "enroll.oda.with.account.step5":
      "On the list of accounts, tap your account for {0}.",
    "enroll.oda.with.account.step6":
      "Under the “Okta FastPass” section, tap Setup, then follow the instructions.",
    "enroll.oda.with.account.step7":
      "After your setup is complete, return here to try signing in again.",
    "enroll.oda.without.account.explanation":
      "To sign in with Okta FastPass, you’ll need to set up Okta Verify on this device.",
    "enroll.oda.without.account.step1":
      "If you don’t have Okta Verify installed, <a href='{0}' target='_blank' class='download-ov-link' id='download-ov'>download the app.</a>",
    "enroll.oda.without.account.step4":
      "Finish setting up your account in Okta Verify, then try signing in again.",
    "enroll.appleStore": "the App Store",
    "enroll.googleStore": "Google Play",
    "enroll.copy.ios":
      "Apple®, App Store, and the Apple logo are trademarks of Apple Inc.",
    "enroll.copy.android":
      "Google Play and the Google Play logo are trademarks of Google LLC.",
    "enroll.explanation.mdm":
      "To access this app, your device needs to meet your organization's security requirements. Follow the instructions below to continue.",
    "enroll.oda.org.copyLink": "Copy sign-in URL to clipboard",
    "enroll.oda.org.copyLink.success": "Sign-in URL copied.",
    "enroll.mdm.copyLink": "Copy link to clipboard",
    "enroll.mdm.copyLink.success": "Link copied.",
    "enroll.mdm.step.copyLink": "Tap the Copy Link button below.",
    "enroll.mdm.step.pasteLink":
      "On this device, open your browser, then paste the copied link into the address bar.",
    "enroll.mdm.step.followInstructions":
      "Follow the instructions in your browser to set up <$1>{0}</$1>.",
    "enroll.mdm.step.relogin":
      "Logout and re-login and then try accessing the app again.",
    "oie.safe.mode.title": "Authenticator set up unavailable",
    "idx.error.server.safe.mode.enrollment.unavailable":
      "Set up is temporarily unavailable due to server maintenance. Try again later.",
    "oie.primaryauth.submit": "Sign in",
    "oie.user.profile.lastname": "Last name",
    "oie.user.profile.firstname": "First name",
    "oie.user.profile.primary.email": "Email",
    "oie.user.profile.secondary.email": "Secondary email",
    "oie.user.profile.middleName": "Middle name",
    "oie.user.profile.honorificPrefix": "Honorific prefix",
    "oie.user.profile.honorificSuffix": "Honorific suffix",
    "oie.user.profile.primaryEmail": "Primary email",
    "oie.user.profile.title": "Title",
    "oie.user.profile.displayName": "Display name",
    "oie.user.profile.nickName": "Nickname",
    "oie.user.profile.profileUrl": "Profile URL",
    "oie.user.profile.mobilePhone": "Mobile phone",
    "oie.user.profile.primaryPhone": "Primary phone",
    "oie.user.profile.streetAddress": "Street address",
    "oie.user.profile.city": "City",
    "oie.user.profile.state": "State",
    "oie.user.profile.zipCode": "ZIP Code",
    "oie.user.profile.postalCode": "Postal code",
    "oie.user.profile.countryCode": "Country code",
    "oie.user.profile.postalAddress": "Postal address",
    "oie.user.profile.preferredLanguage": "Preferred language",
    "oie.user.profile.locale": "Locale",
    "oie.user.profile.timezone": "Time zone",
    "oie.user.profile.userType": "User type",
    "oie.user.profile.employeeNumber": "Employee number",
    "oie.user.profile.costCenter": "Cost center",
    "oie.user.profile.organization": "Organization",
    "oie.user.profile.division": "Division",
    "oie.user.profile.department": "Department",
    "oie.user.profile.managerId": "Manager ID",
    "oie.user.profile.manager": "Manager",
    "oie.selfservice.unlock_user.success.message":
      "You can log in using your existing username and password.",
    "oie.selfservice.unlock_user.failed.message":
      "We are unable to unlock your account at this time, please contact your administrator",
    "oie.selfservice.user.unlock.not.allowed":
      "Self Service Unlock is not allowed at this time. Please contact support for assistance.",
    "idx.session.expired":
      "You have been logged out due to inactivity. Refresh or return to the sign in screen.",
    "oie.registration.is.not.enabled":
      "Sign up is not enabled for this organization.",
    "oie.forgot.password.is.not.enabled":
      "Forgot password is not enabled for this organization.",
    "idx.return.error":
      "Could not process this email link. Return to the screen where you requested it.",
    "idx.return.stale":
      "This email link is out of date. Return to the screen where you requested it.",
    "idx.invalid.forgot.password.token":
      "Your reset password token is no longer valid.",
    "idx.invalid.reset.password.token":
      "Your reset password token is no longer valid. Contact your administrator to obtain a new token.",
    "idx.reset.password.activating.user":
      "Reset password cannot be performed while the user is activating. Contact your administrator to obtain a new token.",
    tagsNotAllowed: "The field must not contain HTML tags",
    "api.authn.error.PASSCODE_INVALID": "Invalid code. Try again.",
    "oie.feature.disabled":
      "The requested feature is not enabled in this environment.",
    "authfactor.challenge.suspended_factor":
      "This factor is suspended for your account due to too many failed attempts",
    "oie.post.password.update.auth.failure.error":
      "Your password has been updated but there was a problem signing you in. Please try again or contact your administrator.",
    "oie.assurance.unsatisfiable":
      'Unable to login to "{0}" at this time. For assistance, please contact your administrator.',
    "errors.E0000150":
      "You have reached the limit of sms requests, please try again later.",
    "errors.E0000151":
      "You have reached the limit of call requests, please try again later.",
    "oie.return.to.original.tab": "Close this window anytime.",
    "idx.operation.cancelled.by.user": "Operation cancelled by user.",
    "idx.transferred.to.new.tab": "Flow continued in a new tab.",
    "idx.return.link.expired":
      "This email link has expired. To resend it, return to the screen where you requested it.",
    "idx.return.link.otponly.title":
      "Return to the location where you initiated the verification process and enter this one-time password.",
    "idx.return.link.otponly.request": "Request from:",
    "idx.return.link.otponly.warning":
      "If you didn’t request this verification code, you can safely ignore this message. Your account isn’t compromised and can’t be accessed without this code.",
    "idx.enter.otp.in.original.tab":
      "Enter the OTP in your original authentication location.",
    "idx.return.link.otponly.accessing.app": "Accessing {0}",
    "idx.return.link.otponly.browser.on.os": "{0} on {1}",
    "geolocation.formatting.all": "{0}, {1}, {2}",
    "geolocation.formatting.partial": "{0}, {1}",
    "device.code.activate.title": "Activate your device",
    "device.code.activate.subtitle":
      "Follow the instructions on your device to get an activation code",
    "device.code.activate.label": "Activation Code",
    "device.code.activated.success.title": "Device activated",
    "device.code.activated.error.title": "Device not activated",
    "idx.device.activated":
      "Follow the instructions on your device for next steps",
    "idx.device.not.activated.consent.denied":
      "Your device cannot be activated because you did not allow access",
    "idx.device.not.activated.internal.error":
      "Your device cannot be activated because of an internal error",
    "idx.invalid.device.code": "Invalid code. Try again.",
    "idx.operation.cancelled.on.other.device": "Access denied on other device.",
    "idx.expired.activation.token":
      "Your account activation link is no longer valid. Request a new activation email below.",
    "idx.missing.activation.token":
      "This can happen if you have already activated your account, or if the URL you are trying to use is invalid. Contact your administrator for further assistance.",
    "idx.activating.inactive.user":
      "Your account has been temporarily suspended. Contact your administrator for further assistance.",
    "idx.request.activation.email":
      "New activation link requested. If your information matches our records, you will receive a new activation link in your inbox soon.",
    "oie.activation.request.email.title.expire": "Activation link has expired",
    "oie.activation.request.email.title.invalid":
      "Activation link no longer valid",
    "oie.activation.request.email.title.suspended": "Account suspended",
    "oie.activation.request.email.title.submitted": "Request submitted",
    "oie.activation.request.email.button": "Request activation email",
    "oie.browser.error.NotAllowedError":
      "The operation either timed out or was not allowed.",
    "authfactor.challenge.soft_token.invalid_passcode":
      "Your code doesn't match our records. Please try again.",
    "authfactor.challenge.soft_token.used_passcode":
      "Each code can only be used once. Please wait for a new code and try again.",
    "oie.tooManyRequests": "Too many attempts. Try again later.",
    E0000010: "Server is unable to respond at the moment.",
    "idx.error.registration.unavailable":
      "Registration is currently unavailable.",
    "idx.error.user.not.assigned.to.app":
      "User is not assigned to this application.",
    "idx.error.code.user_not_assigned":
      "You are not allowed to access this app. To request access, contact an admin.",
  },
};
