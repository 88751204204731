import React from "react";
import "./ErrorComponent.css";
import SecurityIcon from "./images/SecurityIcon.png";
import EANewLogo from "./images/EA-Logo-New.svg";

const Oops = () => {
    const gotoHomePage = () => {
        window.location.href = process.env.REACT_APP_MYPROFILE_URL;
    };
    return (
        <div className="space-x-2">
            <header className="px-4 bg-blue px-5-py-2">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-between">
                        <div className="w-full"></div>
                    </div>
                </div>
            </header>
            <div className="flex" style={{ height: "60px" }}>
                <div
                    className="w-full ea-new-logo"
                    style={{
                        position: "relative",
                    }}
                >
                    <img src={EANewLogo} alt="EA logo"></img>
                </div>
            </div>
            <div className="bg-shape">
                <div
                    className="block m-auto py-32"
                    style={{
                        padding: "15rem 0",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    <div className="max-w-3xl text-center">
                        <img
                            className="m-auto"
                            alt="Security Icon"
                            src={SecurityIcon}
                        ></img>
                        <h1
                            className="incomplete-pass"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            Oops!
                        </h1>
                        <p className="text-gray-600 text-md my-6">
                            Looks like you don't have access to this page.
                        </p>
                        <p className="text-gray-600 text-md my-6">
                            Please return
                            <span
                                onClick={gotoHomePage}
                                style={{
                                    color: "#007DBC",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                }}
                            >
                                {" "}
                                home{" "}
                            </span>
                            or call Service Desk at 55555
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Oops;
