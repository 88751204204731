import React from "react";
const date = new Date();
const Footer = () => (
    <div className="footer">
        © {date.getFullYear()} Electronic Arts Inc. This portal is authorized
        for EA employees, approved contractors & partners only.
    </div>
);

export default Footer;
