import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./ErrorComponent.css";
import inCompletePass from "./images/Incomplete.png";
import EANewLogo from "./images/EA-Logo-New.svg";
export class ErrorComponent extends Component {
    render() {
        return (
            <div className="space-x-2">
                <header className="px-4 bg-blue px-5-py-2">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-between">
                            <div className="w-full"></div>
                        </div>
                    </div>
                </header>
                <div className="flex" style={{ height: "60px" }}>
                    <div
                        className="w-full ea-new-logo"
                        style={{
                            position: "relative",
                        }}
                    >
                        <img alt="EA logo" src={EANewLogo}></img>
                    </div>
                </div>
                <div className="bg-shape">
                    <div
                        className="block m-auto py-32"
                        style={{
                            padding: "15rem 0",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <div className="max-w-3xl text-center">
                            <img
                                className="m-auto"
                                alt="Incomplete pass logo"
                                src={inCompletePass}
                            ></img>
                            <h1 className="incomplete-pass">
                                Incomplete pass!
                            </h1>
                            <p className="text-gray-600 text-md my-6">
                                The page you were looking for can’t be found.
                            </p>
                            <p className="text-gray-600 text-md my-6">
                                <strong>Need help?</strong> Check out our{" "}
                                <Link className="primaryLink" to="/faq">
                                    FAQ Page.
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorComponent;
