import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    useHistory,
    Switch,
} from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config";
import CustomLoginComponent from "./Login";
import Footer from "./footer/Footer";
import ErrorComponent from "./ErrorComponent";
import { initGA } from "./Analytics";
import Oops from "./Oops";
import NotFound from "./NotFound";

const oktaAuth = new OktaAuth({ ...config });
const HasAccessToRouter = () => {
    const history = useHistory();

    useEffect(() => {
        initGA();
    });

    const customAuthHandler = () => {
        history.push("/login");
    };

    const onAuthResume = async () => {
        history.push("/login");
    };

    const restoreOriginalUri = async (oktaAuth, originalUri) => {
        history.replace(
            toRelativeUrl(originalUri || "/", window.location.origin)
        );
    };

    return (
        <Security
            oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
            onAuthRequired={customAuthHandler}
        >
            <div className="route-wrapper">
                <Switch>
                    <Route path="/" exact component={CustomLoginComponent} />
                    {/* <Route
                        path="/implicit/callback"
                        render={() => (
                            <LoginCallback
                                errorComponent={ErrorComponent}
                                onAuthResume={onAuthResume}
                            />
                        )}
                    /> */}
                    <Route
                        path="/login"
                        exact
                        component={CustomLoginComponent}
                    />
                    <Route
                        path="/implicit/callback"
                        exact
                        component={NotFound}
                    />
                    <Route path="/not-found" exact component={NotFound} />
                    <Route
                        path="/myprofile"
                        exact
                        component={CustomLoginComponent}
                    />
                    <Route path="*" render={(props) => <Oops {...props} />} />
                </Switch>
            </div>
            <Footer />
        </Security>
    );
};

const App = () => (
    <div className="app-wrapper">
        <Router>
            <HasAccessToRouter />
        </Router>
    </div>
);

export default App;
