const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const ISSUER =
  process.env.REACT_APP_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

  export default {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: process.env.REACT_APP_BASE_URL + "/implicit/callback",
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  };
