import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import config from "./config";
import { useOktaAuth } from "@okta/okta-react";
import widgetConfig from "./widget-config";
import EANewLogo from "./images/EA_Wordmark_B_RGB.png";
import { GAevent, GAException } from "./Analytics";
import { useState } from "react";
let intervalId;
const Login = () => {
    const { oktaAuth } = useOktaAuth();
    const [loadFooter, setLoadFooter] = useState(false);
    useEffect(() => {
        async function fetchData() {
            const result = await fetcher();
            if (result && result.length) {
                setLoadFooter(true);
                clearInterval(intervalId);
                if (result[0] && result[0].href) {
                    // window.location.href = result[0].href;
                    window.location.href = `${process.env.REACT_APP_DEFAULT_OKTA_DOMAIN}`;
                }
            }
        }
        intervalId = setInterval(() => {
            fetchData();
        }, 500);

        const interactionCodeFlag =
            process.env.REACT_APP_USEINTERACTIONCODEFLOW;
        const { pkce, issuer, clientId, redirectUri, scopes } = config;
        const widget = new OktaSignIn({
            /**
             * Note: when using the Sign-In Widget for an OIDC flow, it still
             * needs to be configured with the base URL for your Okta Org. Here
             * we derive it from the given issuer for convenience.
             */
            baseUrl: issuer ? issuer.split("/oauth2")[0] : "",
            useInteractionCodeFlow:
                interactionCodeFlag === "true" ? true : false,
            clientId,
            redirectUri,
            logo: EANewLogo,
            i18n: widgetConfig,
            authParams: {
                pkce,
                issuer,
                display: "page",
                scopes,
            },
            features: {
                router: false,
                securityImage: false,
                rememberMe: true,
                autoPush: true,
                webauthn: true,
                smsRecovery: true,
                callRecovery: false,
                emailRecovery: false,
                selfServiceUnlock: false,
                multiOptionalFactorEnroll: true,
                deviceFingerprinting: true,
                useDeviceFingerprintForSecurityImage: true,
                trackTypingPattern: true,
                hideSignOutLinkInMFA: false,
                hideBackToSignInForReset: false,
                customExpiredPassword: true,
                idpDiscovery: true,
                passwordlessAuth: false,
                consent: false,
                showPasswordToggleOnSignInPage: true,
                registration: false,
                redirectByFormSubmit: true,
                restrictRedirectToForeground: true,
                showPasswordRequirementsAsHtmlList: true,
            },
        });

        widget.renderEl(
            { el: "#sign-in-widget" },
            (res) => {
                console.log("url", process.env.REACT_APP_MYPROFILE_URL);
                GAevent("Login Widget", "User logged in successfully");
                // oktaAuth.session.setCookieAndRedirect(
                //     res.tokens.accessToken.accessToken,
                //     process.env.REACT_APP_MYPROFILE_URL
                // );
                window.location.href = `${process.env.REACT_APP_DEFAULT_OKTA_DOMAIN}/login/sessionCookieRedirect?checkAccountSetupComplete=true&token=${res.tokens.accessToken.accessToken}&redirectUrl=${process.env.REACT_APP_MYPROFILE_URL}`;
            },
            (err) => {
                GAException(err);
                throw err;
            }
        );
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            <div id="sign-in-widget" />
        </>
    );
};
const fetcher = async () => {
    const nextButton = document.getElementsByClassName(
        "siw-main-view identify primary-auth"
    );
    const ssoButton = document.getElementsByClassName(
        "social-auth-button social-auth-general-idp-button no-translate link-button"
    );
    if (nextButton && nextButton.length) {
        return nextButton;
    } else if (ssoButton && ssoButton.length) {
        return ssoButton;
    }
};

export default Login;
